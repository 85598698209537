.form-footerbar .container{
    color: black;
    background-color: #f5f5f5;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
}
.form-footerbar a{
    color: #222;
}

.form-footerbar a:hover{
    color: #ff9f0d;
}
.form-footerbar{
    flex: 0 0 auto;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1030;
}



