
/* .request-card input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('/src/lib/img/calendar.png') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
} */

.btn-warning-delete {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url('/src/lib/img/dell2.png') no-repeat;
    width: 25px;
    height: 25px;
    border-width: thin;
}

.btn-warning-edit {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url('/src/lib/img/edit.png') no-repeat;
    width: 25px;
    height: 25px;
    border-width: thin;
}

.btn-primary-look{
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url('/src/lib/img/look.png') no-repeat;
    width: 25px;
    height: 25px;
    border-width: thin;
}
.btn-record-view-edit{
    min-width: 6em;
}