.breadcrumb {
    background-color: #fff!important;
    /* display: inline; */
    font-size: .82rem;
}
.breadcrumb li a, .breadcrumb li.active a, .breadcrumb li a:hover, .breadcrumb li.active a:hover, .breadcrumb li a:active, .breadcrumb li.active a:active {
    text-decoration: none;
    border-bottom: 1px solid #999;
    color: #999;
}
.breadcrumb > li + li:before {
    padding: 0 5px;
    color: #646669;
    content: "|";
}