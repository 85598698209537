@font-face {
  font-family: 'ProbaPro-Medium';
  src: url('./lib/fonts/ProbaPro/ProbaPro-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'ProbaPro-Regular';
  src: url('./lib/fonts/ProbaPro/ProbaPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProbaPro-Bold';
  src: url('./lib/fonts/ProbaPro/ProbaPro-Bold.ttf') format('truetype');
}