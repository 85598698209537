.navbar-title{
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;
    color: #767d83;
    margin-right: 6rem;
}
.navbar-nav .dropdown a{
    color: #000 !important;
    text-wrap: wrap;
    max-width: 16em;
}


.navbar-title:hover{
    text-decoration: none;
    color: #767d83;
}

.nav-item{
    color: #767b83;
    display: block;
    padding: .5rem 1rem;
    transition: all 0.2s ease-in;
}

.nav-item:hover{
    color: #a76116;
    text-decoration: none;
}

.nav-item.active{
    color: #a76116;
}

.form-main-navbar{
    background-color: white;
}

.navbar-guest-title{
    height: 12em;
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;
    color: black;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.navbar-guest-title a{
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;
    color: black;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.navbar-guest-title a:hover{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
}

.nav-guest-item{
    color: black;
    display: block;
    padding: .5rem 1rem;
    transition: all 0.2s ease-in;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 13px;
}

.nav-guest-item:hover{
    color: black;
}

.nav-guest-item.active{
    color: #a76116;
}

.form-guest-bg-gradient{
    background-image: linear-gradient(to right, #f7efc2  0%, #e1eea6 51%, #a3814fb0  100%);
    background-image: url('https://bienen-ass.com/demo_img_1.jpg');
    background-repeat: no-repeat;
}

.form-guest-navbar-login{
    color: #fff;
    border-color: #343a40; 
    background-color: #392f1c;
    text-decoration: none;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 2.75rem;
    border-radius: 10.5rem;
    max-width: 10em;
}

@media (min-width: 993px) { 
    
.form-guest-navbar-login{
    align-self: center;
}

.navbar-nav{
    align-items: center;
    align-self: center;
}
 }

.form-guest-navbar-login:hover{
    text-decoration: none;
    color: #000;
    background-color: #7a4fa300;
    border: 2px solid #39351c;
}
.home-image img{
    filter: invert(28%) sepia(13%) saturate(1269%) hue-rotate(329deg) brightness(100%) contrast(119%);
}