/* .form-control {  
border: none!important;
} */

input:focus {
border-color: none!important;
box-shadow: none!important;
}

.MuiInput-root {
  position: relative;
}
.MuiInput-colorSecondary.MuiInput-underline:after {
  border-bottom-color: #000000;
}
.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 1;
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  visibility: visible;
  border-bottom: 2px solid transparent;
  pointer-events: none;
  background-size: 100% 20px;
  background-image: linear-gradient(to right, #d9c2f7 0%, #a6c1ee 51%, #7a4fa3b0 100%);
}

.MuiInput-underline:hover::after {
    transform: scaleX(1);
  }
.MuiInput-underline.Mui-focused:after {
  transform: scaleX(1);
}
.MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #f44336;
}
.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #000000;
  pointer-events: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #000000;
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: dotted;
}
@media (hover: none) {
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
}