
.btn-primary-look-Login{
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background: url('/src/lib/img/eyeHide.svg') no-repeat;
  /* background: url('/src/lib/img/lockClose.svg') no-repeat; */
  width: 25px;
  height: 25px;
  border-width: thin;
  margin-left: -1.6em !important;
  position: absolute;
  margin-top: 0.5em;
  right: 2em;
  z-index:122;
}
.btn-primary-look-Login.active{
    background: url('/src/lib/img/eyeShow.svg') no-repeat;
    /* background: url('/src/lib/img/lockOpen.svg') no-repeat; */
  }
#FormikLogin .form-control{
  border-radius: 0px;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}