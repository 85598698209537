
.page-item-header-fixed{
	position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
	top: 3.6em;
	z-index: 101;
}

.page-item-header-fixed table, .page-item-header-fixed .card-body{
  margin-bottom: 0;
  padding-bottom: 0;
}
