.card-header:first-child {
    border-bottom: none;
    background-color: white;
}
.card-first:first-child {
    border: none;
}
.card{
    border: none !important;
}
.text-dark-customizable {
    text-align: center;
}