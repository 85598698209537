@font-face {
  font-family: 'eUkraineMedium';
  src: url('./lib/fonts/e-Ukraine/e-Ukraine-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'eUkraineRegular';
  src: url('./lib/fonts/e-Ukraine/e-Ukraine-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'eUkraineBold';
  src: url('./lib/fonts/e-Ukraine/e-Ukraine-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'eUkraineLight';
  src: url('./lib/fonts/e-Ukraine/e-Ukraine-Light.otf') format('truetype');
}

@font-face {
  font-family: 'eUkraineThin';
  src: url('./lib/fonts/e-Ukraine/e-Ukraine-Thin.otf') format('truetype');
}

@font-face {
  font-family: 'eUkraineUltraLight';
  src: url('./lib/fonts/e-Ukraine/e-Ukraine-UltraLight.otf') format('truetype');
}