.page-company-list-number{
    width: 50px;
    max-width: 51px;
}
.page-company-list-edr{
    width: 112px;
    max-width: 116px;
}
.page-company-list-view{
    width: 100px;
    max-width: 106px;
}