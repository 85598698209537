/* //table */
.box-container-section table {
  /* // font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif; */
  font-family: eUkraineRegular;
  font-size: 14px;
  text-align: center;
	scroll-snap-align: start;
}
table {
  width: 100%;
}
  
tr {
  scroll-snap-align: start;
}
    
.carousel-img-size {
 max-width: 80vw;
 max-height: 500px;
} 
.carousel-img-container-size {
 /* width: 80vw; */
 height: 500px;
}
    
th {
  background: #fff;
   /* // color: black; */
  padding: 15px 2px;
  white-space: wrap;

  border: none;
  /* border-bottom: 2px solid #000000; */
  vertical-align: top;


  text-align: left!important;
  vertical-align: top;
  position: relative;

  min-height: 45px;   
  font-size: 14px; 
  font-family: eUkraineUltraLight;
  color:#000000;
}    
th .column-th{
  min-height: 45px;   
  border-bottom: 2px solid #000000;
  text-align: left!important;

  font-family: eUkraineUltraLight;
  color:#000000;
}

table thead tr td, table thead tr th {
  padding-right: 10px!important;
}
/* // table .size-column {width: 120px; */
/* //   } */

th.size-column {    min-width: 19em;     }
th.size-column-max {    max-width: 10em;  
  padding-right: 10px;
  padding-left: 10px;
  white-space: normal;
} 
th.size-column-max-date-start {
  min-width: 6em;
}
th.size-column-max-date {
  min-width: 8em;
}
th.size-column-max-datedoc { 
  min-width: 12em;
}
td.size-column-max-content { 
  font-size: 14px;
}

td {
  border: none;
  border-bottom: 0.5px solid #d2d3d6;
}
td {
  color:#000000; 
  vertical-align: top;
  text-align: left;
  padding: 2px;

  font-family: eUkraineUltraLight;
  font-size: 12px;
  
}

.paginator{
  text-align: center;
  margin: 5px;
}
.paginator a{
  text-align: center;
  margin: 20px 5px;
  color: #84878afb;
}

.paginator a:hover{
  color: #d2d3d6;
}

.paginator .active{
  color: #5a5c5e;
  font-weight: bold;
  color:#844deb;
}

.wd-th-small-3 {
  min-width: 3em; 
  width: 3em; 
}

.wd-th-small {
  min-width: 6em; 
  width: 6em; 
}
.wd-th-middle{
  min-width: 200px; 
}
.wd-th-big{
  min-width: 20em;
}
.wd-th-big-vh1{
  min-width: 20em;
  width: 10vh;
}.wd-th-big-vh5{
  min-width: 20em;
  width: 50vh;
}.wd-th-big-vh6{
  min-width: 20em;
  width: 60vh;
}.wd-th-big-vh10{
  min-width: 20em;
  width: 100vh;
}
  
.table-responsive{
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

[data-tooltip] {
  position: relative; /* Относительное позиционирование */ 
  z-index: 10;
 }
 [data-tooltip]::after {
  content: attr(data-tooltip); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  width: 300px; /* Ширина подсказки */
  left: 0; top: 0; /* Положение подсказки */
  background: #fff; /* Синий цвет фона */
  color: #6c757d; /* Цвет текста */
  padding: 0.5em; /* Поля вокруг текста */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
  pointer-events: none; /* Подсказка */
  opacity: 0; /* Подсказка невидима */
  transition: 1s; /* Время появления подсказки */
  font-family: eUkraineUltraLight;
  font-size: 12px;
 } 
 [data-tooltip]:hover::after {
  opacity: 1; /* Показываем подсказку */
  top: 2em; /* Положение подсказки */
 }

.text-align-left {
  text-align:left;
}
.text-align-right {
  text-align:right;
}
.registry-item-click {
  text-decoration-line: underline;
  color: #6437b8;
  font-weight: bold;
  cursor: pointer;
}
.form-btn, .submit-back{
    color: #fff;
    border-color: #343a40; 
    background-color: #381c39;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0 1rem;
    border-radius: 10.5rem;
}
.form-btn:hover, .submit-back:hover{
    text-decoration: none;
    color: #000;
    background-color: #7a4fa300;
    border: 2px solid #381c39;
}
.form-btn{
  padding: 0.375rem 2.75rem;
  font-size: 1rem;
  border: 2px solid #381c39;
}

.doc-header-text{
  font-family: eUkraineRegular,sans-serif;
  text-align: center;
  width: 100%;
  align-self: center;
  font-weight: 350;
  line-height: 1.2;
  font-size: 32px;
  margin-left: 12px;
}
.button-submit-send button{
  color: #fff;
  border-color: #343a40; 
  background-color: #381c39;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  border-radius: 10.5rem;
  line-height:normal;
  padding: 0.375rem 2.75rem;
  font-size: 1rem;
  border: 2px solid #381c39;
  margin: 0 0.5em;
}
.button-submit-send button:hover{
  text-decoration: none;
  color: #000;
  background-color: #7a4fa300;
  border: 2px solid #381c39;
}
.filter-text{
    font-family: eUkraineUltraLight;
    font-size: 12px;
}

.scheme-item {
  color: #000000;
  vertical-align: top;
  text-align: left;
  background: white;
  padding: 2px;
  font-family: eUkraineUltraLight;
  border-bottom: 0.5px solid #d2d3d6;
  /* font-size: 12px; */
  text-decoration: none;
}