body {
  margin: 0;
  font-family: eUkraineRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: eUkraineRegular;
  font-size: 18px;
  background-color: #fff!important;
  margin: 0;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.nav-bar {
  color: rgba(255, 255, 255, 0.75);
  overflow: hidden;
  height: 50px;
  padding: 1em 2em 1em 2em;

}

.nav-bar a {
  color: #aef9d7;
  text-align: center;
  padding-left: 10px;
  text-decoration: none;
  font-size: 16px;
}

.nav-bar a.active {
  color: #fff;
}

.nav-bar a:hover {
  color: rgb(243, 223, 136);
}

.data-time {
  text-decoration: none;
  font-size: 16px;
  background-color: white;
  color: black;
  float: left
}

#ui-content {
  padding: 1em;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
}

.container {
  padding: 0;
  box-shadow: 0 0 20px rgb(0, 0, 0, 10%);
  cursor: unset !important;
}


input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin:  0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input:disabled {
  background-color: #fafbfc;
}

#login-form {
  width: 400px;
  height: 300px;
  padding: 20px;
  background-color: #fff;
  /* //position: absolute;
  //top: 50%;
  //left: 50%;
  //margin-right: -50%;
  //transform: translate(-50%, -50%); */

}
#login-form .error-message {
  color: red;
}

#login-form  button {
  width: 100%;
}

button {
  background-color: #7a6221;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
}

.button-signOut {
  background-color: white;
  color: black;
  padding: 0;
  margin: 0;
  float: right
}
.wrapper{
  background-color: #fff;
}

article {
  display: flex;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04);
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 15px;

}
article .img-wrap {
  margin-right: 15px;
  width: 200px;
  height: 200px;

}
article .img-wrap img {
  height: 100%;
  width: 100%;
}

article .title > a {
  font-weight: 500;
  font-size: 18px;
  color: black;
}

article .title > .author {
  font-size: 12px;
  line-height: 20px;
  color: #757575;
}




a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: blue;
}

.group {
  margin-top: 6px;
}

.row {
  --bs-gutter-x: 0;width: auto;
  flex-shrink: unset;
  padding-bottom: 4em;
}
li > p {
  margin-top: 16px;
}

.header-text{
  font-family: eUkraineRegular,sans-serif;
  text-align: left;
  width: 100%;
  align-self: center;
  font-weight: 350;
  line-height: 1.2;
  font-size: 32px;
  margin-left: 12px;
}


.header-conteiner{
  font-size: 40px;
  color: white;
  padding: 15px 30px;
  margin: 8px 0;
  text-align: center;
  width: 100%;
}

.header-conteiner h2{
  font-family: eUkraineMedium;
  font-size: 28px;
  color: white;
  text-align:justify;
  margin-bottom: 10px;
}

.header-conteiner p{
  font-family: eUkraineRegular;
  font-size: 18px;
  color: white;
  text-align: justify;
}

.box-container-section{
  flex-shrink: unset;
  text-align: left;
  color: #000000;
  margin: 1em 2em 1em 2em;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.Flipped, .Flipped .Content
{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); /* IE 9 */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */
}

.top {
  position: fixed;
  text-align:center;
  width: 100%;
  overflow-x:scroll;
}
.box-container-section a{
  font-family: eUkraineMedium;
  text-align: left;
  color: #000000;
}

.box-container-section a:hover{
   color: #b88b37;
}

.link-section, .link-section:hover{
   color: #b88b37;
}

.box-container-section h2{
  font-family: eUkraineMedium;
  margin-top: 1em;
  font-size: 28px;
  margin-bottom: 8px;
  line-height: 32px;
}

.box-container-section p{
  font-family: eUkraineRegular;
  font-size: 18px;
}

.page-item-header-fixed{
	position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
	top: 0;
	z-index: 101;
}

.page-item-header-fixed .container{
	box-shadow: none;
}

.diagrams{
  height: 40em;
  min-width: 22em;
}

.diagrams-container{
  background: #e7eef3;
  max-height: 55em;
  display: flex;
  justify-content: center;
}

.item-container-border-top{
  border-top: 3px solid #000000;
}

