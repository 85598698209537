.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: eUkraineRegular, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.right-0 {
  right: 0 !important;
}
.top-0 {
  top: 0 !important;
}
.float-right {
  float: right;
}

body, html, #root{
  min-height: 100vh;
}
#root{
  display: flex;
  flex-direction: column;
}
.header-bar{
  flex: 0 0 auto;
}
.App{
  flex: 1 0 auto;
}
