.account-panel {
  display: flex;
  /*justify-content: center;
  align-items: center;*/
  margin: 20px 50px;
  min-height: 50vh;
  flex: 1 0 auto;
  background-color: #fff;
}

.auth-card {
  width: 500px;
  margin: 15px;
  background-color: #fff0;
  border: 0;
}

#auth-card-login {
  background-color: #fff0;
  border: 0;
}

.auth-card-title:first-child {
  font-size: 42px!important;
  text-align: center;
  font-family: eUkraineBold;
  text-transform: none;
  color: #000;
  text-align: left;
}

.h5 {
  font-family: eUkraineRegular;
  font-size: 18px!important;
  text-transform: none;
  color: #000;
  text-align: left;
}

.form-label {
  font-family: eUkraineMedium;
  font-size: 14px!important;
}

.form-control{
  font-family: eUkraineRegular;
  font-size: 14px!important;
  color: #000!important;
  border: none!important;
  border-bottom: 2px solid #000!important;
}

.my-2 {
  font-family: eUkraineRegular;
  font-size: 14px!important;
  color: #767d83;
}

.my-3 {
  font-family: eUkraineRegular;
  color: #767d83;
}

#btn-login,
#btn-register,
.btn-light-theme  {
  font-family: eUkraineRegular;
  background-color: #381c39;
  border: none;
  color: white;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  /* padding: 6px 4em 6px 4em; */
  /* margin: 10px 0 0 0; */
  white-space: nowrap;
}

#btn-login  {
  opacity: 100;
  border: 2px solid #381c39;
}

#btn-warning-theme  {
  font-family: eUkraineRegular;
  background-color: #677787;
  border: 1px solid transparent;
  color: white;
  border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}
#btn-warning-theme:hover {
  background: #5bb9bb;
  color: #fff;
  text-decoration: none;
}
.btn-secondary:hover {
  background-color: #5bb9bb!important;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent!important;
}

#btn-login:hover  {
  text-decoration: none;
  color: #000;
  background-color: #7a4fa300;
  border: 2px solid #381c39;
}

#btn-register {
  padding: 6px 2em 6px 2em;
}

#btn-register:hover,
.btn-light-theme:hover {
  background: #5bb9bb;
  color: #fff;
  text-decoration: none;
}

#btn-login:focus,
#btn-login:active,
#btn-register:focus,
#btn-register:active {
  outline: none;
}

.btn-login-conteiner {
  text-align: center;
  color: #fff;
}
  

#btn-password-reset {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  font-family: eUkraineRegular;
  border: none;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  margin-left: 20px;
}

#btn-password-reset:hover {
  border: none;
  text-decoration: underline;
}

#btn-password-reset:focus,
#btn-password-reset:active {
  border: none;
  outline: none;
}

.card-body{
  padding: 0!important;
}